<template>
    <div
        ref="steps"
        class="wizard-steps-wrapper"
    >
        <div class="wizard-steps">
            <template v-for="(step, id) in steps">
                <div
                    :key="id"
                    :ref="`item_${step.step}`"
                    class="wizard-step"
                    :class="{ 'active-step': step.routeName === $route.name }"
                    @click="timelessTest(step.step, step.routeName)"
                >
                    <template v-if="finishStep(step)">
                        <div
                            class="wizard-step__number"
                            :class="{'wizard-step__number--finish' : finishStep(step)}"
                        >
                            <span
                                class="wizard-step__progress"
                                :class="{'wizard-step__progress--finish' : finishStep(step)}"
                            />
                        </div>
                    </template>
                    <template v-else>
                        <div class="wizard-step__number">
                            {{ step.step }}
                            <span
                                class="wizard-step__progress"
                                :class="{'wizard-step__progress--finish' : finishStep(step)}"
                            />
                        </div>
                    </template>
                    <div class="wizard-step__text">
                        <span
                            :class="`wizard-step__text--image ${step.image}`"
                            :style="finishStep(step) ? 'background: #1161E0' : 'background: #AFB2B9'"
                        />
                        <p
                            class="wizard-step__text--name"
                            :class="{'wizard-step__text--name-finish' : finishStep(step)}"
                        >
                            {{ step.text }}
                        </p>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import {
    ACTION_SET_CURRENT_STEP
} from '@/store/modules/wizard/action-types'
import { mapWizardFields } from '@/store/modules/wizard'

const { mapActions: mapWizardActions } = createNamespacedHelpers('wizard')
export default {
    name: 'WizardStepsHeader',
    data() {
        return {
            steps: [
                {
                    routeName: 'wizard-address',
                    image: 'adress',
                    text: 'Address',
                    step: 1
                },
                {
                    routeName: 'wizard-general',
                    image: 'clipboard',
                    text: 'General',
                    step: 2
                },
                {
                    routeName: 'wizard-document',
                    image: 'file-text',
                    text: 'document',
                    step: 3
                },
                {
                    routeName: 'wizard-agreement',
                    image: 'edit-pen',
                    text: 'agreement',
                    step: 4
                },
                // {
                //     routeName: 'wizard-payment',
                //     image: 'dollar-sign',
                //     text: 'payment',
                //     step: 5
                // },
                {
                    routeName: 'wizard-faq',
                    image: 'help-circle',
                    text: 'faq',
                    step: 5
                },
                {
                    routeName: 'wizard-exam',
                    image: 'globe',
                    text: 'exam',
                    step: 6
                }
            ]
        }
    },
    computed: {
        ...mapWizardFields([
            'wizard.steps.currentStep',
            'wizard.steps.lastValidStep',
            'wizard.steps.allStepsCount',
            'wizard.exam.status'
        ])
    },
    watch: {
        currentStep(val) {
            this.scrollToItem(val);
        }
    },
    mounted() {
        this.scrollToItem(this.currentStep);
        this.ACTION_SET_CURRENT_STEP
    },
    methods: {
        ...mapWizardActions([
            ACTION_SET_CURRENT_STEP
        ]),
        finishStep(val) {
            if (this.status === 'finish' && this.currentStep === 7) { return true }
            return val.step <= this.lastValidStep && this.currentStep > val.step
        },
        timelessTest(step, route) {
            //  || (this.status === 'finish' && this.currentStep == 7)
            if (step > this.lastValidStep) { return }
            this.ACTION_SET_CURRENT_STEP({ step })
            this.$router.push({ name: route })
        },
        scrollToItem(id) {
            const item = this.$refs[`item_${id}`][0]
            const scrollDistance = item.offsetLeft
            const { steps } = this.$refs
            steps.scrollTo({
                left: scrollDistance - 30,
                behavior: 'smooth'
            });
        }
    }
}
</script>

<style lang="scss">
    .wizard-steps {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        justify-content: space-between;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        //height: 150px;
        width: 1200px;
        padding: 20px;
        margin-bottom: 40px;
        cursor: pointer;
        .wizard-step {
            display: flex;
            align-items: center;
            position: relative;
            min-width: 120px;
            &:first-child .wizard-step__progress {
                display: none;
            }
            &__progress {
                display: block;
                position: absolute;
                height: 2px;
                background: #ddd;
                top: 13px;
                right: 34px;
                width: 50px;
                &--finish {
                    height: 2px;
                    background: $main-color;
                }
            }
            &__number {
                border: 1px solid #D7D7D7;
                color: #fff;
                box-sizing: border-box;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #D7D7D7;
                z-index: 9;
                position: relative;
                margin-right: 5px;
                &--finish {
                    background: $main-color;
                    border-color: #fff;
                    &::before {
                        content: '';
                        display: block;
                        width: 16px;
                        height: 12px;
                        mask-image: url('~@/assets/img/svg/check.svg');
                        background: $white;
                    }
                }
            }
            &__text {
                display: flex;
                &--image {
                    display: block;
                    width: 30px;
                    height: 30px;
                    mask-repeat: no-repeat;
                    mask-position: center;
                    background: #AFB2B9;
                    margin-right: 5px;
                    mask-size: 20px;
                    &.adress {
                        mask-image: url('~@/assets/img/svg/adress.svg');
                    }
                    &.clipboard {
                        mask-image: url('~@/assets/img/svg/clipboard.svg');
                    }
                    &.file-text {
                        mask-image: url('~@/assets/img/svg/file-text.svg');
                    }
                    &.edit-pen {
                        mask-image: url('~@/assets/img/svg/edit-pen.svg');
                    }
                    &.dollar-sign {
                        mask-image: url('~@/assets/img/svg/dollar-sign.svg');
                    }
                    &.help-circle {
                        mask-image: url('~@/assets/img/svg/help-circle.svg');
                    }
                    &.globe {
                        mask-image: url('~@/assets/img/svg/globe.svg');
                    }
                }
                &--name {
                    font-size: 14px;
                    line-height: 30px;
                    color: #AFB2B9;
                    text-transform: capitalize;
                }
                &--name-finish {
                    color: #181818;
                    font-weight: 600;
                }
            }
            &.active-step {
                .wizard-step__number {
                    border: 2px solid $main-color;
                    color: $main-color;
                    font-weight: 500;
                    background-color: #fff;
                }
                .wizard-step__text {
                    &--name {
                        color: #181818;
                        font-weight: bold;
                    }
                }
            }
            .progress-wizard-exam {
                display: none;
            }
        }
    }

    .wizard-steps-wrapper {
        max-width: 1200px;
        overflow: auto;
        margin: 40px auto 0 auto;
        &::-webkit-scrollbar {
            height: 0;
        }
    }
</style>
