<template>
    <main-layout>
        <template #content>
            <div class="wizard-heading">
                <h1 class="wrapper">
                    Fill your profile
                </h1>
            </div>
            <wizard-steps-header />
            <div
                class="wizard-content"
                :class="`page-${pageClass}`"
            >
                <router-view
                    @goToNextStep="goToNextStep"
                    @goToPrevStep="goToPrevStep"
                />
            </div>
        </template>
    </main-layout>
</template>

<script>

import MainLayout from '@/layouts/MainLayout';
import WizardStepsHeader from '@/components/wizard/WizardStepsHeader';
import { mapWizardFields } from '@/store/modules/wizard'
import { createNamespacedHelpers } from 'vuex';

import {
    ACTION_GET_CURRENT_STEP,
    ACTION_SET_CURRENT_STEP
} from '@/store/modules/wizard/action-types'

const { mapActions: mapWizardActions } = createNamespacedHelpers('wizard')

export default {
    stepsRoutes: [
        'wizard-address',
        'wizard-general',
        'wizard-document',
        'wizard-agreement',
        // 'wizard-payment',
        'wizard-faq',
        'wizard-exam'
    ],
    name: 'IndexVue',
    components: {
        MainLayout,
        WizardStepsHeader
    },
    computed: {
        ...mapWizardFields([
            'wizard.steps.currentStep',
            'wizard.steps.lastValidStep',
            'wizard.steps.allStepsCount'
        ]),
        checkFirstStep() {
            return this.currentStep === 1
        },
        checkLastStep() {
            return this.currentStep === this.allStepsCount
        },
        pageClass() {
            return this.$route.name
        }
    },
    mounted() {
        this.getCurrentStep()
    },
    methods: {
        ...mapWizardActions([
            ACTION_GET_CURRENT_STEP,
            ACTION_SET_CURRENT_STEP
        ]),
        goToNextStep() {
            if (this.currentStep === this.allStepsCount) return
            this.currentStep += 1
            this.ACTION_SET_CURRENT_STEP({ step: this.currentStep })
            this.lastValidStep = this.currentStep
            this.$router.push(this.$options.stepsRoutes[this.currentStep - 1]).catch(() => {})
            window.scrollTo({ top: 0, behavior: 'smooth' })
        },
        goToPrevStep(e) {
            e.preventDefault()
            if (this.currentStep === 1) return
            this.currentStep -= 1
            this.ACTION_SET_CURRENT_STEP({ step: this.currentStep })
            this.lastValidStep = this.currentStep
            this.$router.push(this.$options.stepsRoutes[this.currentStep - 1]).catch(() => {})
            window.scrollTo({ top: 0, behavior: 'smooth' })
        },
        async getCurrentStep() {
            const response = await this.ACTION_GET_CURRENT_STEP()
            this.currentStep = response.data === 0 ? 1 : response.data
            this.lastValidStep = response.data - 1
            this.$router.push(this.$options.stepsRoutes[this.currentStep - 1]).catch(() => {})
        }
    }
}
</script>

<style lang="scss">
    .wizard-heading {
        padding: 70px 0 120px;
        margin-bottom: -75px;
        background: url('~@/assets/img/bg-laptop.jpg');
        font-weight: bold;
        font-size: 56px;
        color: $white;
    }
    .wizard-content {
        display: block;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        margin-bottom: 110px;
        &__buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .btn-base.btn-main {
            width: 200px;
            height: 52px;
            text-transform: capitalize;
            @media (max-width:480px) {
                width: 100%;
            }
            &:first-child{
                @media (max-width:480px) {
                    margin-bottom: 20px;
                }
            }
        }
        &.page-wizard-faq {
            background: transparent;
            border: none;
            box-shadow: none;
            .wizard__title {
                display: none;
            }
            .page-faq {
                padding: 0;
            }
        }
    }
    .wizard__title {
        font-weight: bold;
        font-size: 24px;
        line-height: 40px;
        color: #181818;
        padding: 23px 40px 15px;
        border-bottom: 1px solid #dedede;
        //display: flex;
        //justify-content: space-between;
        align-items: center;
    }
    .wizard-content .wizard__form, .wizard-content .wizard__title{
        padding: 40px;
        top: 0;
        box-shadow: none;
        margin: 0;
        max-width: 100%;
        @media (max-width:640px) {
            padding: 40px 20px;
        }
    }

</style>
